import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../Config/Api";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

//notification
export const fetchNotifications = createAsyncThunk(
  "notification/fetchNotifications",
  async (postData) => {
    try {
      const results = await Api("headerNotification", "POST", postData);
      return { data: results.data, page: postData.page };
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const fetchNotificationPage = createAsyncThunk(
  "notification/fetchNotificationPage",
  async (postData) => {
    try {
      const results = await Api("get_Notifications", "POST", postData);
      return { data: results.data, page: postData.page };
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/deleteNotification",
  async (postData) => {
    try {
      const body = {
        notification_id: postData.id,
      };
      const results = await Api("deleteNotification", "POST", body);
      let returnData = {
        data: results.data,
        id: postData.id,
      };
      return returnData;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const deleteAllNotification = createAsyncThunk(
  "notification/deleteAllNotification",
  async (postData) => {
    try {
      const results = await Api("deleteAllNotification", "POST", postData);
      return results.data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const readNotification = createAsyncThunk(
  "notification/readNotification",
  async (postData) => {
    try {
      // console.log(postData);
      const body = {
        notification_id: postData.notification_id,
      };
      const results = await Api("updateNotification", "POST", body);
      let returnData = {
        data: results.data,
        id: postData.notification_id,
      };
      return returnData;
    } catch (error) {
      console.log(error.message);
    }
  }
);
//notification

//country
export const fetchCountry = createAsyncThunk(
  "notification/fetchCountry",
  async (d) => {
    try {
      const results = await Api("getCountryCodeList");
      return results.data;
    } catch (error) {
      console.log(error.message);
    }
  }
);
//country

//wallet
export const fetchWallet = createAsyncThunk(
  "notification/fetchWallet",
  async (postData) => {
    try {
      const results = await Api("getWalletbalance", "POST", postData);
      return results.data;
    } catch (error) {
      console.log(error.message);
    }
  }
);
//wallet

//verifymobilenumber
export const sendOtpForMobileVerify = createAsyncThunk(
  "notification/sendOtpForMobileVerify",
  async (postData) => {
    try {
      const results = await Api("resend_otp", "POST", postData);
      if(results?.data?.status===1){
        toast.success(results?.data?.message);
      } else{
        toast.error(results?.data?.message);
      }
      return results.data;
    } catch (error) {
      console.log(error.message);
    }
  }
);
//verifymobilenumber

const initialState = {
  notificationHeader: [],
  notificationCount: "",
  notificationHeaderTotalPageCount: 0,
  notificationHeaderPage: 0,
  notificationPageList: [],
  notificationTotalPageCount: 0,
  notificationPage: 0,
  country: [],
  wallet: "",
  encryptedHash: "",
  notificationFetchStatus: "loading", // idle | loading | succeeded | failed
  notificationPageFetchStatus: "loading", // idle | loading | succeeded | failed
  notificationDeleteStatus: "loading",
  notificationDeleteAllStatus: "loading",
  notificationReadStatus: "loading",
  countryStatus: "loading",
  walletStatus: "loading",
  mobileOtpStatus: "loading",
  error: null,
  isQRModal: Cookies.get("QRUniqueHome") ? true : false,
  isTaxiPackage: Cookies.get("taxiPackage") ? true : false,
};

export const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setQrCodeLogin: (state, action) => {
        Cookies.set("QRUniqueHome", action.payload === "taxi" ? false :  true, {
          expires: 1 / 24,
          path: "/",
        });
        Cookies.set("taxiPackage", action.payload === "taxi" ? true :  false, {
          expires: 1 / 24,
          path: "/",
        });
        state.isTaxiPackage = action.payload === "taxi" ? true :  false;
        state.isQRModal = action.payload === "taxi" ? false : action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.notificationFetchStatus = "loading";
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        // console.log(action.payload);
        let { data, page } = action.payload;
        state.notificationFetchStatus = "succeeded";
        state.notificationCount = data.unread;

        if (page > 1 && data.status === 1) {
          state.notificationHeader = state.notificationHeader.concat(
            data?.notificationsData
          );
        } else {
          state.notificationHeader = data?.notificationsData;
        }

        state.notificationHeaderTotalPageCount = data?.totalPageCount;
        state.notificationHeaderPage = page;


      })
      .addCase(fetchNotifications.rejected, (state) => {
        state.notificationFetchStatus = "succeeded";
        // state.error = action.error.message;
      })
      .addCase(fetchNotificationPage.pending, (state) => {
        state.notificationPageFetchStatus = "loading";
      })
      .addCase(fetchNotificationPage.fulfilled, (state, action) => {
        state.notificationPageFetchStatus = "succeeded";
        // console.log(action.payload);
        let { data, page } = action.payload;
        if (page > 1 && data.status === 1) {
          state.notificationPageList = state.notificationPageList.concat(
            data?.notificationsData
          );
        } else {
          state.notificationPageList = data?.notificationsData;
        }
        state.notificationTotalPageCount = data?.totalPageCount;
        state.notificationPage = page;
      })
      .addCase(fetchNotificationPage.rejected, (state) => {
        state.notificationPageFetchStatus = "succeeded";
      })
      .addCase(deleteNotification.pending, (state) => {
        state.notificationDeleteStatus = "loading";
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.notificationDeleteStatus = "succeeded";
        let { data, id } = action.payload;
        if (data?.status === 1) {
          let notificationHeader = [...state.notificationHeader];
          const index = notificationHeader.findIndex(
            (a) => a.Notification.id === id
          );

          const filterNotificationHeader = notificationHeader.filter(
            (n) => n.Notification.id !== id
          );

          if (notificationHeader[index].Notification.is_read === "0") {
            state.notificationCount -= 1;
          }

          state.notificationHeader = filterNotificationHeader;

          let notificationPageList = [...state.notificationPageList];

          const filterPageNotifications = notificationPageList.filter(
            (n) => n.Notification.id !== id
          );

          state.notificationPageList = filterPageNotifications;

          toast.success("Notification Deleted !", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error("Something went wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.notificationDeleteStatus = "succeeded";
        // state.error = action.error.message;
      })
      .addCase(deleteAllNotification.pending, (state) => {
        state.notificationDeleteAllStatus = "loading";
      })
      .addCase(deleteAllNotification.fulfilled, (state, action) => {
        state.notificationDeleteAllStatus = "succeeded";
        // console.log(action.payload);
        if (action.payload?.status === 1) {
          toast.success("All Notifications Deleted !", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          state.notification = [];
          state.notificationCount = "";
        }
      })
      .addCase(deleteAllNotification.rejected, (state) => {
        state.notificationDeleteAllStatus = "succeeded";
      })
      .addCase(readNotification.pending, (state) => {
        state.notificationReadStatus = "loading";
      })
      .addCase(readNotification.fulfilled, (state, action) => {
        state.notificationReadStatus = "succeeded";
        let { data, id } = action.payload;

        if (data?.status === 1) {
          let notificationHeader = [...state.notificationHeader];
          const index = notificationHeader.findIndex(
            (a) => a.Notification.id === id
          );
          if (notificationHeader[index].Notification.is_read === "0") {
            notificationHeader[index].Notification.is_read = "1";
            state.notificationHeader = notificationHeader;
            state.notificationCount -= 1;
          }

          if (Boolean(state.notificationPageList.length)) {
            let notificationPageList = [...state.notificationPageList];
            const ind = notificationPageList.findIndex(
              (a) => a.Notification.id === id
            );
            notificationPageList[ind].Notification.is_read = "1";
            state.notificationPageList = notificationPageList;
          }
        }
      })
      .addCase(readNotification.rejected, (state) => {
        state.notificationReadStatus = "succeeded";
      })
      .addCase(fetchCountry.pending, (state) => {
        state.countryStatus = "loading";
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.countryStatus = "succeeded";
        if (action.payload?.status === 1) {
          state.country = action.payload?.countryList;
        }
      })
      .addCase(fetchCountry.rejected, (state) => {
        state.countryStatus = "succeeded";
      })
      .addCase(fetchWallet.pending, (state) => {
        state.walletStatus = "loading";
      })
      .addCase(fetchWallet.fulfilled, (state, action) => {
        state.walletStatus = "succeeded";
        if (action.payload?.status === 1) {
          state.wallet = action.payload;
        }
      })
      .addCase(fetchWallet.rejected, (state) => {
        state.walletStatus = "succeeded";
      })
      .addCase(sendOtpForMobileVerify.pending, (state) => {
        state.mobileOtpStatus = "loading";
      })
      .addCase(sendOtpForMobileVerify.fulfilled, (state, action) => {
        state.mobileOtpStatus = "succeeded";
      })
      .addCase(sendOtpForMobileVerify.rejected, (state) => {
        state.mobileOtpStatus = "succeeded";
      });
  },
});
export const notification = (state) => state.common.notificationHeader;
export const notificationPageList = (state) =>
  state.common.notificationPageList;
export const notificationTotalPageCount = (state) =>
  state.common.notificationTotalPageCount;
export const notificationPage = (state) => state.common.notificationPage;
export const notificationCount = (state) => state.common.notificationCount;

export const notificationHeaderPage = (state) => state.common.notificationHeaderPage;
export const notificationHeaderTotalPageCount = (state) => state.common.notificationHeaderTotalPageCount;


export const country = (state) => state.common.country;
export const wallet = (state) => state.common.wallet;
// export const isQRModal = (state) => state.common.isQRModal;
export const { setNotification ,setQrCodeLogin} = CommonSlice.actions;
export default CommonSlice.reducer;
