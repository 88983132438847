import axios from "axios";

let cancelToken;
const Api = async (endpoint, method, body, cancelTokenVar = false) => {
  const options = {
    url: process.env.REACT_APP_API_URL+ 'MobileApi/' + endpoint,
    method: method || "GET",
    data: body,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    responseType: "json",
  };

  if (cancelTokenVar) {
    //Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    options.cancelToken = cancelToken.token;
  }

  if (method === "GET") {
    options.data = undefined;
  }

  // console.log("options=>>", options);

  return axios(options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export default Api;
